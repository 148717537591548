import axios from "axios";
import { TokenRequest, TokenResponse } from "types/auth";
import { BASE_URL } from "utils/util";

export const getAuthToken = async (payload: TokenRequest) =>
  axios
    .post<TokenResponse>(`${import.meta.env.VITE_APP_PUBLIC_API_URL}/community-auth/token`, payload)
    .then((res) => {
      if (res.data?.access_token) return res.data;
      return null;
    })
    .catch((err) => {
      console.error(err);
      return null;
    });

export const checkDuplicateId = async (payload: { id: string }) =>
  axios.post<{ duplicate: boolean }>(`${BASE_URL}/user/checkDuplicateId`, payload);

export const checkDuplicateNickname = async (payload: { nickname: string }) =>
  axios.post<{ duplicate: boolean }>(`${BASE_URL}/user/checkDuplicateNickname`, payload);

export const checkDuplicateEmail = async (payload: { email: string }) =>
  axios.post<{ duplicate: boolean }>(`${BASE_URL}/user/checkDuplicateEmail`, payload);

export const checkDuplicatePhone = async (payload: { phone: string }) =>
  axios
    .post<{ duplicate: boolean }>(`${BASE_URL}/user/checkDuplicatePhone`, payload)
    .then((res) => res.data);
